import { useContext, useEffect, useState } from "react";
import {
    TextField,
    FormControlLabel,
    Switch,
    Collapse,
    CircularProgress,
    MenuItem
} from "@mui/material";
import {
    Root,
    classes,
    selectRow
} from "../../assets/styles/settings/PresenterSettings.js";
import _ from "lodash";
import Button from "../../components/UI/Button.js";
import AuthGlobal from "../../context/store/global";
import {
    getPresenterTemplate,
    refreshRestaurant,
    updatePresenterTemplate
} from "../../utils/Restaurants/restaurant-helper.js";
import { useAlert } from "../../hooks/useAlert.js";
import Icon from "../../components/UI/Icon.js";
import { saveImage } from "../../utils/mediaHelper.js";
import Alert from "../../components/UI/Alert.js";
import { useTheme } from "@mui/material/styles";

const PresenterSettings = () => {
    const {
        dispatch,
        globalState: {
            current_restaurant: {
                identifier,
                social_media,
                landing_message,
                landing_button_text,
                landing_button_link,
                button_one_active,
                button_one_position,
                landing_button_two_link,
                landing_button_two_text,
                button_two_active,
                button_two_position,
                landing_button_three_link,
                landing_button_three_text,
                button_three_active,
                button_three_position
            },
            current_restaurant
        }
    } = useContext(AuthGlobal);
    const defaultFont = {
        name: "",
        family: "",
        url: "",
        weight: "",
        size: "",
        style: "",
        line_height: "",
        background: "",
        color: "",
        border_width: "",
        border_color: "",
        border_radius: "",
        padding: "",
        button_width: "",
        button_box_shadow: ""
    };
    const [formData, setFormData] = useState({
        template_id: "1",
        controls: {
            show_logo: "",
            show_item_separator: true,
            accordion_section: true,
            item_counter: true,
            show_back_to_top: true,
            accordion_section_starts_open: true,
            show_splash_page: true,
            show_item_images: false,
            show_service_period_name: true,
            show_read_more_less: false
        },
        colors: {
            primary: "",
            secondary: "",
            tertiary: "",
            background_1: "",
            background_2: "",
            text: ""
        },
        images: {
            header_image: "",
            featured_image: "",
            restaurant_background_image: "",
            restaurant_header_image: "",
            restaurant_favicon_image: "",
            restaurant_meta_image: "",
            item_image_border_radius: "6px"
        },
        fonts: {
            language_selector: { ...defaultFont },
            restaurant_message: { ...defaultFont },
            restaurant_button: { ...defaultFont },
            restaurant_button_2: { ...defaultFont },
            restaurant_button_3: { ...defaultFont },
            collection_name: { ...defaultFont },
            collection_description: { ...defaultFont },
            restaurant: { ...defaultFont },
            collection: { ...defaultFont },
            menu_links: { ...defaultFont },
            menu_header: { ...defaultFont },
            section_links: { ...defaultFont },
            section: { ...defaultFont },
            item_name: { ...defaultFont },
            item_description: { ...defaultFont },
            item_price: { ...defaultFont },
            notations_slider: { ...defaultFont },
            notations: { ...defaultFont },
            search_text: { ...defaultFont },
            section_header: { ...defaultFont },
            menu_footer: { ...defaultFont }
        },
        landing: {
            message: "",
            buttonText: "",
            buttonLink: "",
            buttonOneActive: false,
            buttonOnePosition: "",
            buttonTwoText: "",
            buttonTwoLink: "",
            buttonTwoActive: false,
            buttonTwoPosition: "",
            buttonThreeText: "",
            buttonThreeLink: "",
            buttonThreeActive: false,
            buttonThreePosition: ""
        },
        social: {
            bgColor: "",
            facebook: {
                active: false,
                color: "#3B5998",
                background: "#FFFFFF",
                link: ""
            },
            instagram: {
                active: false,
                color: "#E1306C",
                background: "#FFFFFF",
                link: ""
            },
            twitter: {
                active: false,
                color: "#1DA1F2",
                background: "#FFFFFF",
                link: ""
            },
            pinterest: {
                active: false,
                color: "#BD081C",
                background: "#FFFFFF",
                link: ""
            },
            yelp: {
                active: false,
                color: "#D32323",
                background: "#FFFFFF",
                link: ""
            },
            tiktok: {
                active: false,
                color: "#000000",
                background: "#FFFFFF",
                link: ""
            }
        },
        language_selector: {
            background_color: "",
            text_color: "",
            drawer_text_color: "",
            border_color: "",
            radio_color: ""
        },
        item_styles: {
            item_button: {
                background_color: "",
                border_color: "",
                text_color: "",
                border_width: "1px",
                border_radius: "6px"
            }
        }
    });
    const [images, setImages] = useState({
        header_image: "",
        featured_image: "",
        restaurant_background_image: "",
        restaurant_header_image: "",
        restaurant_favicon_image: "",
        restaurant_meta_image: ""
    });
    const [previewImg, setPreviewImg] = useState({
        header_image: "",
        features_image: "",
        restaurant_background_image: "",
        restaurant_header_image: "",
        restaurant_favicon_image: "",
        restaurant_meta_image: ""
    });

    const [errors, setErrors] = useState({
        template_id: "",
        "colors.primary": "",
        "colors.secondary": "",
        "colors.tertiary": "",
        "colors.background_1": "",
        "colors.background_2": "",
        "colors.text": "",
        "fonts.restaurant_button.background": "",
        "fonts.restaurant_button_2.background": "",
        "fonts.restaurant_button_3.background": "",
        "fonts.restaurant_message.color": "",
        "fonts.restaurant_button.color": "",
        "fonts.restaurant_button_2.color": "",
        "fonts.restaurant_button_3.color": "",
        "fonts.collection_name.color": "",
        "fonts.collection_description.color": "",
        "fonts.restaurant.color": "",
        "fonts.collection.color": "",
        "fonts.menu_links.color": "",
        "fonts.section_links.color": "",
        "fonts.section.color": "",
        "fonts.item_name.color": "",
        "fonts.item_description.color": "",
        "fonts.item_price.color": "",
        "fonts.notations_slider.color": "",
        "fonts.notations.color": "",
        "fonts.search_text.color": "",
        "fonts.section_header.color": "",
        "fonts.menu_footer.color": "",
        "social.bgColor": "",
        "social.facebook.color": "",
        "social.instagram.color": "",
        "social.twitter.color": "",
        "social.pinterest.color": "",
        "social.yelp.color": "",
        "social.tiktok.color": "",
        "language_selector.background_color": "",
        "language_selector.text_color": "",
        "language_selector.drawer_text_color": "",
        "language_selector.radio_color": "",
        "language_selector.border_color": ""
    });

    const [collapsed, setCollapsed] = useState({});
    const [loading, setLoading] = useState(true);
    const [showFormError, setShowFormError] = useState(false);
    const [alert, handleAlert] = useAlert();
    const [showLogo, setShowLogo] = useState("not");
    let colorChangeTimeout;

    const theme = useTheme();

    const handleChange = (target, event, type = "text") => {
        let newData = { ...formData };
        if (type !== "image") {
            newData = _.set(newData, target, event.target.value);
            setErrors((prev) => {
                return _.set(errors, target, "");
            });
            setShowFormError(false);
            if (target === "images.item_image_border_radius") {
                setImages((prev) => ({
                    ...prev,
                    item_image_border_radius: event.target.value
                }));
            }
            setFormData(newData);
        }
        if (type === "image") {
            const path = target.split(".").at(-1);
            if (event.target.type === "file") {
                ((file) =>
                    setImages((prev) => ({
                        ...prev,
                        [path]: file
                    })))(event.target.files[0]);
                event.target.value = null;
            } else {
                newData = _.set(newData, target, event.target.value);
                setFormData(newData);
                setImages((prev) => ({ ...prev, [path]: event.target.value }));
            }
        }
    };

    useEffect(() => {
        console.log("formData", formData?.controls?.show_logo);
        if (formData?.controls?.show_logo === "") {
            setShowLogo("not");
        } else {
            setShowLogo(formData?.controls?.show_logo);
        }
    }, [formData?.controls?.show_logo]);

    useEffect(() => {
        const newPreviews = {};
        Object.keys(images).forEach((k) => {
            if (images[k]) {
                newPreviews[k] =
                    typeof images[k] === "string"
                        ? images[k]
                        : URL.createObjectURL(images[k]);
            } else {
                newPreviews[k] = "";
            }
        });
        setPreviewImg(newPreviews);
    }, [images]);

    const displayName = (str) => {
        if (str === "notations_slider") {
            return "Dietary Restrictions Slider";
        } else if (str === "notations") {
            return "Dietary Restrictions";
        } else if (str === "restaurant_button") {
            return "Restaurant Button 1";
        }
        return str.split("_").map(_.startCase).join(" ");
    };

    useEffect(() => {
        (async () => {
            const { success, response } = await getPresenterTemplate(
                identifier
            );
            if (success && response) {
                const data = response.template;
                const bgColor =
                    data.social_media?.facebook?.background || "#FFFFFF";
                data.landing = {
                    message: landing_message,
                    buttonText: landing_button_text,
                    buttonLink: landing_button_link,
                    buttonOneActive: button_one_active,
                    buttonOnePosition: button_one_position,
                    buttonTwoText: landing_button_two_text,
                    buttonTwoLink: landing_button_two_link,
                    buttonTwoActive: button_two_active,
                    buttonTwoPosition: button_two_position,
                    buttonThreeText: landing_button_three_text,
                    buttonThreeLink: landing_button_three_link,
                    buttonThreeActive: button_three_active,
                    buttonThreePosition: button_three_position
                };
                data.social = {
                    facebook: {},
                    instagram: {},
                    twitter: {},
                    pinterest: {},
                    yelp: {},
                    tiktok: {}
                };
                data.social.bgColor = bgColor;
                if (social_media) {
                    for (const key of Object.keys(social_media)) {
                        data.social[key].link = social_media[key];
                        if (data.social_media) {
                            try {
                                data.social[key].color =
                                    data.social_media[key]?.color || "";
                                data.social[key].active =
                                    data.social_media[key]?.show_social_media ||
                                    false;
                                data.social[key].background =
                                    data.social_media[key].background || "";
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }
                }
                setFormData((prev) => _.merge(prev, data));
                response.template.images && setImages(response.template.images);
                setLoading(false);
            }
        })();
    }, [
        identifier,
        social_media,
        landing_button_link,
        landing_button_text,
        landing_message,
        button_one_active,
        button_one_position,
        landing_button_two_link,
        landing_button_two_text,
        button_two_active,
        button_two_position,
        landing_button_three_link,
        landing_button_three_text,
        button_three_active,
        button_three_position
    ]);

    const isValidColor = (val) => {
        if (typeof val !== "string") {
            return false;
        }
        const isHex = val.match("^#[0-9a-fA-F]+$");
        const isValidLength = [4, 7, 9].includes(val.length);
        return isHex && isValidLength;
    };

    const formInput = (
        label,
        name,
        type = "text",
        { multiline = false } = {}
    ) => {
        const value = _.get(formData, name);
        const errorState = _.get(errors, name)?.length > 0;
        const showImage =
            type === "image" && previewImg[name.split(".").at(-1)];
        return (
            <>
                <TextField
                    label={label}
                    value={value}
                    type={type !== "color" && type !== "image" ? type : "text"}
                    InputLabelProps={{ shrink: true }}
                    className={`${classes.textField} ${
                        multiline ? classes.lessRadioBorder : ""
                    }`}
                    error={errorState}
                    fullWidth
                    helperText={_.get(errors, name)}
                    onChange={(e) => handleChange(name, e, type)}
                    style={{ margin: "15px 0" + (errorState ? " -7px" : "") }}
                    multiline={multiline}
                    rows={multiline ? 8.4 : 1}
                    InputProps={
                        type === "color"
                            ? {
                                  endAdornment: (
                                      <div
                                          style={{
                                              width: "22px",
                                              height: "22px",
                                              border: "1px solid #B4B3B3",
                                              background: value
                                          }}
                                      >
                                          <input
                                              className={
                                                  classes.invisibleColorPicker
                                              }
                                              style={{
                                                  padding: "0 !important"
                                              }}
                                              type="color"
                                              onChange={(e) => {
                                                  clearTimeout(
                                                      colorChangeTimeout
                                                  );
                                                  colorChangeTimeout =
                                                      setTimeout(() => {
                                                          handleChange(name, e);
                                                      }, 100);
                                              }}
                                          />
                                      </div>
                                  )
                              }
                            : type === "image"
                            ? {
                                  endAdornment: (
                                      <span className={classes.uploadButton}>
                                          <Icon name="publish" />
                                          <input
                                              type="file"
                                              className={
                                                  classes.invisibleColorPicker
                                              }
                                              onChange={(e) =>
                                                  handleChange(name, e, type)
                                              }
                                              style={{
                                                  position: "absolute",
                                                  padding: "0 !important"
                                              }}
                                          />
                                      </span>
                                  )
                              }
                            : {}
                    }
                />
                {showImage && (
                    <div className={classes.imageContainer}>
                        <span
                            className={classes.removeImage}
                            onClick={() =>
                                handleChange(
                                    name,
                                    { target: { value: "" } },
                                    type
                                )
                            }
                        >
                            <Icon name="clear" />
                        </span>
                        <img
                            src={previewImg[name.split(".").at(-1)]}
                            alt={displayName(name.split(".").at(-1))}
                            className={classes.image}
                        />
                    </div>
                )}
            </>
        );
    };

    const toggleSwitch = (target) => {
        let newData = { ...formData };
        newData = _.set(newData, target, !_.get(newData, target));
        setFormData(newData);
    };

    const handleSubmit = async () => {
        // Validate fields
        const newErrors = {};
        let valid = true;
        Object.keys(errors).forEach((v) => {
            if (v === "template_id") {
                if (!["1", "2"].includes(_.get(formData, "template_id"))) {
                    newErrors["template_id"] =
                        "Invalid template, currently allowed values are '1' and '2'";
                    valid = false;
                }
                return;
            }
            const color = _.get(formData, v);
            if (color?.length > 0 && !isValidColor(color)) {
                newErrors[v] = "Invalid color format";
                valid = false;
            } else {
                newErrors[v] = "";
            }
        });
        setErrors(newErrors);
        if (!valid) {
            console.log(newErrors);
            setShowFormError(true);
            window.scrollTo({ top: 200 });
            return;
        }
        // ---- End Validate ----
        // Process images
        let payload = {
            ...formData,
            // If the show logo is set to no, then remove the logo
            controls: {
                ...formData.controls,
                show_logo: showLogo === "not" ? "" : showLogo
            }
        };
        let successUpload, image_url;
        for (const key of Object.keys(images)) {
            if (typeof images[key] === "string") {
                payload.images[key] = images[key];
            } else {
                [successUpload, image_url] = await saveImage(
                    images[key],
                    identifier,
                    "presenter/assets"
                );
                if (successUpload) {
                    payload.images[key] = image_url;
                } else {
                    setShowFormError(true);
                    window.scrollTo({ top: 200 });
                    return;
                }
            }
        }
        // ---- End Image Processing
        // Call API to update
        const { success, response } = await updatePresenterTemplate(
            identifier,
            payload
        );
        if (success && response) {
            handleAlert({
                severity: "success",
                title: "Success",
                message: "Presenter Template updated successfully"
            });
            setTimeout(() => {
                refreshRestaurant(dispatch, identifier, current_restaurant);
            }, 2000);
        }
    };

    const formSwitch = (label, name) => {
        const value = _.get(formData, name);
        return (
            <div style={{ height: 44, margin: "15px 0 0" }}>
                <p className={classes.toggleLabel}>{label}</p>
                <FormControlLabel
                    control={
                        <Switch
                            checked={value}
                            classes={{
                                switchBase: classes.switchDisabled
                            }}
                            className={value ? classes.switch : ""}
                        />
                    }
                    name={name}
                    onChange={() => toggleSwitch(name)}
                    label={value ? "Active" : "Inactive"}
                    className={classes.switchLabel}
                />
            </div>
        );
    };

    return !loading ? (
        <Root>
            <Alert {...alert} />
            <Collapse in={showFormError}>
                <div
                    style={{
                        fontSize: 14,
                        background: "#FAD3B8",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        height: 40
                    }}
                >
                    Some errors were found in the form, please fix them and try
                    again
                </div>
            </Collapse>
            <h2 style={{ marginTop: 15 }}>General</h2>
            <hr style={{ margin: "15px 0" }} />
            <div className={classes.columnsContainer} style={{ margin: 0 }}>
                <div className={classes.column} style={{ width: "50%" }}>
                    <div>
                        <TextField
                            select
                            value={formData.template_id}
                            onChange={(e) => handleChange("template_id", e)}
                            label="Template id"
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        sx: selectRow(theme)
                                    },
                                    classes: {
                                        paper: classes.selectRow
                                    }
                                }
                            }}
                            style={{ width: 200 }}
                            className={`${classes.select} ${classes.textField}`}
                        >
                            <MenuItem value="1"> 1</MenuItem>
                            <MenuItem value="2"> 2</MenuItem>
                        </TextField>
                    </div>
                    <div>
                        {formInput(
                            "Restaurant Meta Description",
                            "controls.meta_description",
                            "text",
                            {
                                multiline: true
                            }
                        )}
                    </div>
                </div>
                <div className={classes.column} style={{ width: "50%" }}>
                    {formInput(
                        "Restaurant Meta  Image",
                        "images.restaurant_meta_image",
                        "image"
                    )}
                </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <h2>Controls</h2>
            <hr style={{ margin: "15px 0" }} />
            <div className={classes.columnsContainer}>
                <div className={classes.column}>
                    <TextField
                        select
                        value={showLogo}
                        onChange={(e) => setShowLogo(e.target.value)}
                        label="Show logo"
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                            MenuProps: {
                                PaperProps: {
                                    sx: selectRow(theme)
                                },
                                classes: {
                                    paper: classes.selectRow
                                }
                            }
                        }}
                        className={`${classes.select} ${classes.textField}`}
                    >
                        <MenuItem value="not">Do Not Show Logo</MenuItem>
                        <MenuItem value="top">Top</MenuItem>
                        <MenuItem value="bottom">Bottom</MenuItem>
                    </TextField>
                    {formSwitch("Item counter", "controls.item_counter")}
                    {formSwitch(
                        "Show splash page",
                        "controls.show_splash_page"
                    )}
                    {formSwitch(
                        "Show Empty Decimals",
                        "controls.show_empty_decimals"
                    )}
                    {formSwitch(
                        "Show Read more/less",
                        "controls.show_read_more_less"
                    )}
                </div>
                <div className={classes.column}>
                    {formSwitch(
                        "Show item separator",
                        "controls.show_item_separator"
                    )}
                    {formSwitch(
                        "Show back to top",
                        "controls.show_back_to_top"
                    )}
                    {formSwitch(
                        "Show item images",
                        "controls.show_item_images"
                    )}
                    {formSwitch(
                        "Show Currency Symbol",
                        "controls.show_currency_symbol"
                    )}
                </div>
                <div className={classes.column}>
                    {formSwitch(
                        "Accordion Section",
                        "controls.accordion_section"
                    )}
                    {formSwitch(
                        "Accordion section starts open",
                        "controls.accordion_section_starts_open"
                    )}
                    {formSwitch("Show Item Price", "controls.show_item_price")}
                    {formSwitch(
                        "Show Service Period Name",
                        "controls.show_service_period_name"
                    )}
                </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <h2>Colors</h2>
            <hr style={{ margin: "15px 0" }} />
            <div className={classes.columnsContainer}>
                <div className={classes.column}>
                    {formInput("Primary", "colors.primary", "color")}
                    {formInput("Background 1", "colors.background_1", "color")}
                </div>
                <div className={classes.column}>
                    {formInput("Secondary", "colors.secondary", "color")}
                    {formInput("Background 2", "colors.background_2", "color")}
                </div>
                <div className={classes.column}>
                    {formInput("Tertiary", "colors.tertiary", "color")}
                    {formInput("Text", "colors.text", "color")}
                </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <h2>Item Styles</h2>
            <hr style={{ margin: "15px 0" }} />
            <div className={classes.columnsContainer}>
                <div className={classes.column}>
                    {formInput(
                        "Button Background Color",
                        "item_styles.item_button.background_color",
                        "color"
                    )}
                    {formInput(
                        "Button Border Width",
                        "item_styles.item_button.border_width",
                        "text"
                    )}
                </div>
                <div className={classes.column}>
                    {formInput(
                        "Button Border Color",
                        "item_styles.item_button.border_color",
                        "color"
                    )}
                    {formInput(
                        "Border Radius",
                        "item_styles.item_button.border_radius",
                        "text"
                    )}
                </div>
                <div className={classes.column}>
                    {formInput(
                        "Button Text Color",
                        "item_styles.item_button.text_color",
                        "color"
                    )}
                </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <h2>Images</h2>
            <hr style={{ margin: "15px 0" }} />
            <div className={classes.columnsContainer}>
                <div className={classes.column}>
                    {formData.template_id === "2" &&
                        formInput(
                            "Header Image",
                            "images.header_image",
                            "image"
                        )}
                    {formInput(
                        "Background Image",
                        "images.restaurant_background_image",
                        "image"
                    )}
                    {formData.template_id === "1" &&
                        formInput(
                            "Section Image Background",
                            "images.section_background_image",
                            "image"
                        )}
                    {formInput(
                        "Favicon Image",
                        "images.restaurant_favicon_image",
                        "image"
                    )}
                </div>
                <div className={classes.column}>
                    {formData.template_id === "2" &&
                        formInput(
                            "Featured Image",
                            "images.featured_image",
                            "image"
                        )}
                    {formInput(
                        "Restaurant Header Image",
                        "images.restaurant_header_image",
                        "image"
                    )}
                    {formData.template_id === "1" &&
                        formInput(
                            "Item Images Border Radius",
                            "images.item_image_border_radius",
                            "text"
                        )}
                </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <h2>Language Selector Styles</h2>
            <hr style={{ margin: "15px 0" }} />

            <div className={classes.columnsContainer}>
                <div className={classes.column}>
                    {formInput(
                        "Selector Button background Color",
                        "language_selector.background_color",
                        "color"
                    )}
                    {formInput(
                        "Selector Drawer Text Color",
                        "language_selector.drawer_text_color",
                        "color"
                    )}
                </div>
                <div className={classes.column}>
                    {formInput(
                        "Selector Button Border Color",
                        "language_selector.border_color",
                        "color"
                    )}
                    {formInput(
                        "Radio Button Color",
                        "language_selector.radio_color",
                        "color"
                    )}
                </div>
                <div className={classes.column}>
                    {formInput(
                        "Selector Button Text and Icon Color",
                        "language_selector.text_color",
                        "color"
                    )}
                </div>
            </div>
            <hr style={{ margin: "15px 0" }} />
            <h2>Fonts</h2>
            <hr style={{ marginTop: "15px" }} />
            {Object.keys(formData.fonts).map((key) => (
                <div key={key}>
                    <h4
                        style={{
                            margin: "0px",
                            padding: "10px",
                            borderBottom: "1px solid",
                            borderTop: "1px solid",
                            textAlign: "center",
                            cursor: "pointer",
                            width: "100%",
                            height: "100%"
                        }}
                        onClick={() => {
                            setCollapsed((prev) => ({
                                ...prev,
                                [key]: !prev[key]
                            }));
                        }}
                    >
                        {displayName(key)}
                    </h4>
                    <Collapse in={collapsed[key]}>
                        <div className={classes.columnsContainer}>
                            <div className={classes.column}>
                                {formInput("Name", `fonts.${key}.name`)}
                                {key !== "language_selector" &&
                                    formInput("Weight", `fonts.${key}.weight`)}
                                {key !== "language_selector" &&
                                    formInput(
                                        "Line height",
                                        `fonts.${key}.line_height`
                                    )}
                                {(key === "restaurant_button" ||
                                    key === "restaurant_button_2" ||
                                    key === "restaurant_button_3") && (
                                    <>
                                        {formInput(
                                            "Border Width",
                                            `fonts.${key}.border_width`
                                        )}
                                        {formInput(
                                            "Padding",
                                            `fonts.${key}.padding`
                                        )}
                                    </>
                                )}
                            </div>
                            <div className={classes.column}>
                                {formInput("Family", `fonts.${key}.family`)}
                                {key !== "language_selector" &&
                                    formInput("Size", `fonts.${key}.size`)}
                                {key !== "language_selector" &&
                                    formInput(
                                        "Color",
                                        `fonts.${key}.color`,
                                        "color"
                                    )}
                                {(key === "restaurant_button" ||
                                    key === "restaurant_button_2" ||
                                    key === "restaurant_button_3") && (
                                    <>
                                        {formInput(
                                            "Border Color",
                                            `fonts.${key}.border_color`,
                                            "color"
                                        )}
                                        {formInput(
                                            "Button Width",
                                            `fonts.${key}.button_width`
                                        )}
                                    </>
                                )}
                            </div>
                            <div className={classes.column}>
                                {formInput("Url", `fonts.${key}.url`)}
                                {key !== "language_selector" &&
                                    formInput("Style", `fonts.${key}.style`)}
                                {(key === "restaurant_button" ||
                                    key === "restaurant_button_2" ||
                                    key === "restaurant_button_3") && (
                                    <>
                                        {formInput(
                                            "Background",
                                            `fonts.${key}.background`,
                                            "color"
                                        )}
                                        {formInput(
                                            "Border Radius",
                                            `fonts.${key}.border_radius`
                                        )}
                                        {formInput(
                                            "Button Box Shadow",
                                            `fonts.${key}.button_box_shadow`
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </Collapse>
                </div>
            ))}
            <Button
                innerText="Save"
                type="primary"
                color="orange"
                style={{ marginLeft: "auto", marginBottom: 15, marginTop: 15 }}
                onClick={handleSubmit}
            />
        </Root>
    ) : (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: 500
            }}
        >
            <CircularProgress
                style={{ color: "#CC5803", width: 60, height: 60 }}
            />
        </div>
    );
};

export default PresenterSettings;
